import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Box, Drawer, useMediaQuery, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import logo from '../../asset/logo.png';
import './navbar.css';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navLinks = [
    { label: 'Home', path: '/' },
    { label: 'Product', path: '/product' },
    { label: 'About Us', path: '/about' },
    { label: 'Contact', path: '/contact' },
    { label: 'Blog', path: '/blog' },
    { label: 'Gallery', path: '/gallery' }
  ];

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#002F6C' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem', backgroundColor: '#002F6C' }}>
        {/* Logo Section */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Export Bihar Logo" style={{ width: '150px', height: 'auto' }} />
        </Box>

        {/* Contact Info Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <PhoneIcon sx={{ color: '#FFC107', fontSize: '1.5rem' }} />
          <Typography
            component="a"
            href="tel:+917761042572"
            sx={{ color: '#FFFFFF', textDecoration: 'none', '&:hover': { color: '#FFD54F' } }}
          >
            Any question call me +91-7761042572
          </Typography>
        </Box>
      </Toolbar>

      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#F4F4F4', padding: '0 1rem' }}>
        {/* Address with Location Icon on the Left */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LocationOnIcon sx={{ color: '#002F6C', fontSize: '1.5rem' }} />
          <Typography
            component="a"
            href="https://www.google.com/maps/search/?api=1&query=Khaptahan,Jagdishpur,Bhojpur,Bihar"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ color: '#002F6C', textDecoration: 'none', '&:hover': { color: '#00A9A5' } }}
          >
            Khaptahan, Jagdishpur, Bhojpur, Bihar
          </Typography>
        </Box>

        {/* Menu Items on the Right */}
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
              <MenuIcon sx={{ color: '#002F6C' }} />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer}
              sx={{
                '& .MuiDrawer-paper': {
                  width: '50%',
                  backgroundColor: '#F4F4F4',
                  padding: 2,
                },
              }}
            >
              <Box sx={{ padding: 2 }}>
                <Typography variant="h6" sx={{ color: '#002F6C', mb: 2 }}>
                  Menu
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  {navLinks.map((item, index) => (
                    <Grid item xs={12} key={index} sx={{ textAlign: 'center' }}>
                      <Typography
                        component={Link}
                        to={item.path}
                        onClick={toggleDrawer}
                        sx={{
                          color: '#333333',
                          textDecoration: 'none',
                          display: 'block',
                          padding: '10px',
                          transition: 'background-color 0.3s, color 0.3s',
                          '&:hover': {
                            color: '#00A9A5',        // Text color on hover
                            backgroundColor: '#EFEFEF', // Background color on hover
                            borderRadius: 1
                          },
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 3 }}>
            {navLinks.map((item, index) => (
              <Typography
                key={index}
                variant="button"
                component={Link}
                to={item.path}
                sx={{
                  color: '#002F6C',
                  textDecoration: 'none',
                  '&:hover': { color: '#00A9A5' },
                }}
              >
                {item.label}
              </Typography>
            ))}
          </Box>
        )}
      </Toolbar>

      {/* Social Media Icons Fixed in Bottom Right Corner */}
      <Box sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}>
        <IconButton
          sx={{
            backgroundColor: '#25D366',
            borderRadius: '50%',
            '&:hover': { backgroundColor: '#1ebea5' },
            animation: 'pulse 1.5s infinite'
          }}
          href="https://wa.me/7763913361"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon sx={{ color: 'white', fontSize: 40 }} />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: '#0088cc',
            borderRadius: '50%',
            '&:hover': { backgroundColor: '#0077b3' },
            animation: 'pulse 1.5s infinite'
          }}
          href="https://t.me/pulseofnation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TelegramIcon sx={{ color: 'white', fontSize: 40 }} />
        </IconButton>
      </Box>
    </AppBar>
  );
};

export default Navbar;
