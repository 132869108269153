import React from 'react';
import { Box, Container, Typography, Grid, TextField, Button, Link, IconButton } from '@mui/material'; // Added IconButton here
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneIcon from '@mui/icons-material/Phone';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#002F6C', color: '#FFF', pt: 5, pb: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Company Information */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#FFC107' }}>Our Company</Typography>
            <Typography variant="body2">Export Bihar is dedicated to bridging Bihar's rich heritage with global markets. We specialize in exporting high-quality, authentic products that reflect the state's unique culture and craftsmanship. Join us in bringing Bihar's excellence to the world!</Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#FFC107' }}>Quick Links</Typography>
            <Box>
              <Link href="/" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Home</Link>
              <Link href="/services" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>Services</Link>
              <Link href="/about" color="inherit" underline="none" sx={{ display: 'block', mb: 1 }}>About Us</Link>
              <Link href="/contact" color="inherit" underline="none" sx={{ display: 'block' }}>Contact</Link>
              
            </Box>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#FFC107' }}>Contact</Typography>
            <Typography variant="body2">Email: info@exportbihar.com</Typography>

            <Typography variant="body2">  <PhoneIcon sx={{ color: '#FFC107', fontSize: '1.5rem' }} />
          <Typography
            component="a"
            href="tel:+916205641861"
            sx={{ color: '#FFFFFF', textDecoration: 'none', '&:hover': { color: '#FFD54F' } }}
          >
              +91-6205641861
          </Typography>
          </Typography>
            <Typography variant="body2">     <Typography
            component="a"
            href="tel:+917991106305"
            sx={{ color: '#FFFFFF', textDecoration: 'none', '&:hover': { color: '#FFD54F' } }}
          >
             <PhoneIcon sx={{ color: '#FFC107', fontSize: '1.5rem' }} /> +91-7991106305
          </Typography></Typography>
          </Grid>

          {/* Newsletter Signup */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom sx={{ color: '#FFC107' }}>Stay Updated</Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>Subscribe to our newsletter for the latest news and updates.</Typography>
            <TextField variant="filled" fullWidth placeholder="Email Address" sx={{ bgcolor: '#FFF', borderRadius: 1, mb: 1 }} />
            <Button variant="contained" sx={{ bgcolor: '#00A9A5', color: '#FFF', width: '100%' }}>Subscribe</Button>
          </Grid>
        </Grid>

        {/* Social Media */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <IconButton href="https://facebook.com" sx={{ color: '#FFC107' }}><FacebookIcon /></IconButton>
          <IconButton href="https://twitter.com" sx={{ color: '#FFC107' }}><TwitterIcon /></IconButton>
          <IconButton href="https://www.linkedin.com/in/traction-technology-a554b5212" sx={{ color: '#FFC107' }}><LinkedInIcon /></IconButton>
          <IconButton
      color="primary"
      href="https://www.youtube.com/@ExportBihar"
      target="_blank"
      aria-label="YouTube"
    >
      <YouTubeIcon style={{ color: '#FF0000' }} fontSize="large" />
    </IconButton>
        </Box>

        {/* Copyright */}
        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
          &copy; {new Date().getFullYear()} Exportbihar.Com. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
