import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VerifiedIcon from '@mui/icons-material/Verified';

const Certificate = () => {
  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' }, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h5" gutterBottom>
          Company Overview
        </Typography>
        <Typography variant="body1">
          Export Bihar, a subsidiary of Traction and Technology Enterprise, is dedicated to bringing Bihar’s finest agricultural products to international markets.
          Specializing in the export of high-quality grains like rice and maize, we pride ourselves on transparency, quality control, and a strong commitment to our customers.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: 2, border: '1px solid #E0E0E0', borderRadius: 1, backgroundColor: 'white', boxShadow: 1 }}>
            <BusinessIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>Registered Business</Typography>
              <Typography variant="body2">
                GST Number: <strong style={{ fontSize: "1rem", fontWeight: 'bold' }}>[10CDLPG0788F1ZS]</strong>
              </Typography>
              <Typography variant="body2">
                Registered Address: 1ST FLOOR, P1/10, PATRAKARNAGAR KANKARBAGH, VIDYAPURI COLONY, Patna, Bihar, 800020
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: 2, border: '1px solid #E0E0E0', borderRadius: 1, backgroundColor: 'white', boxShadow: 1 }}>
            <ImportExportIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>Import Export Certified</Typography>
              <Typography variant="body2">
                IEC Code: <strong style={{ fontSize: "1rem", fontWeight: 'bold' }}>[CDLPG0788F]</strong>
              </Typography>
              <Typography variant="body2">Certified for Quality Exports</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: 2, border: '1px solid #E0E0E0', borderRadius: 1, backgroundColor: 'white', boxShadow: 1 }}>
            <VerifiedIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6" sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>Authenticity Guaranteed</Typography>
              <Typography variant="body2">Our products meet international standards for quality and safety.</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Certificate;
