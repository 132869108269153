import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage/Homepage';
import Navbar from '../src/component/nav/Navbar'
import Footer from './component/footer/Footer';
import AboutUs from './pages/about/About';
import ProductPage from './pages/product/Product';
import Contact from './pages/contact/Contact'
function App() {
  return (
    <Router>
      <Navbar/>
      <div style={{paddingTop:"150px"}}></div>
      <Routes>
      
     
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/contact" element={<Contact />} />

        

        {/* Uncomment these lines when you create these components
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
