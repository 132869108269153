import React from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';

const ProductCard = ({ product }) => {
  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 2,
        boxShadow: 3,
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'scale(1.05)', // Scale effect on hover
        },
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image={product.image}
        alt={product.name}
        sx={{
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          objectFit: 'cover',
        }}
      />
      <CardContent>
        <Typography variant="h6" component="div" sx={{ color: '#002F6C', fontWeight: 'bold' }}>
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        {/* <Typography variant="h5" sx={{ color: '#FFC107', mt: 1 }}>
          ${product.price}
        </Typography> */}
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: '#00A9A5', // Button background color
            color: '#FFF',
            '&:hover': {
              bgcolor: '#007A7A', // Button hover color
            },
          }}
        >
          See More
        </Button>
      </Box>
    </Card>
  );
};

export default ProductCard;
