import React, { useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import Vido from '../../asset/back.mp4';

const Hero = () => {
  const [loading, setLoading] = useState(true);

  const handleVideoLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        height: '80vh',
        overflow: 'hidden',
        '& video': {
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.1)', // Overlay color
        },
      }}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <video autoPlay loop muted onLoadedData={handleVideoLoad}>
        <source src={Vido} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Box
        sx={{
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          color: '#FFC107', // Text color
          textAlign: 'center',
        }}
      >
        {["Welcome", "to", "Our", "Export-Import", "Business"].map((word, index) => (
          <Typography
            key={index}
            variant="h4"
            sx={{
              opacity: 0,
              animation: `fadeIn 1s forwards`,
              animationDelay: `${index * 1}s`, // Delay each word
            }}
          >
            {word}
          </Typography>
        ))}
      </Box>
      <style>
        {`
          @keyframes fadeIn {
            to {
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Hero;
