import React from 'react'
import Hero from './Hero'
import Product from './Product'
import Process from './HowitWork'
import TestimonialComponent from './Testimonial'
import Certificate from './Certificate'
import DirectorProfile from '../../component/homepage/Director'
import WhyBihar from './Whybihar'
const Homepage = () => {
  return (
    <>
    <Hero/>
    <Product/>
    <Certificate/>
    <Process/>
    <DirectorProfile/>
    <WhyBihar />
    <TestimonialComponent/>
    </>
  )
}

export default Homepage