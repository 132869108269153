import React from 'react';
import { Box, Typography, Avatar, Paper, Grid } from '@mui/material';
import { Business, Lightbulb, AccountCircle } from '@mui/icons-material';
import veera from '../../asset/veera.webp'
import govind from '../../asset/govind.jpg'
import abhisek from '../../asset/abhishek.jpg'

const DirectorProfile = () => {
  const teamMembers = [
    {
      name: 'Govind Gaurav',
      position: 'Director, Export Bihar',
      company: 'A subsidiary of Traction and Technology Enterprise',
      vision: 'To empower local businesses in Bihar by creating global opportunities, ensuring growth and prosperity for the region.',
      image: govind,
    },
    {
      name: 'Abhishek Kumar',
      position: 'Marketing and Client Relations',
      vision: 'To foster strong client relationships and promote the value of our products across international markets.',
      image: abhisek,
    },
    {
      name: 'Avinash Kumar',
      position: 'Supply Chain and Logistics',
      vision: 'To ensure efficient, timely, and cost-effective logistics that support our clients and suppliers globally.',
      image: veera,
    },
  ];

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 4 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Our Leadership Team
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} sx={{ padding: 3, borderRadius: 3, textAlign: 'center' }}>
              <Avatar src={member.image} alt={member.name} sx={{ width: 100, height: 100, margin: 'auto', mb: 2 }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main', mt: 1 }}>
                {member.name}
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ color: 'text.secondary', mt: 1, mb: 2 }}>
                <AccountCircle sx={{ marginRight: 0.5 }} />
                <Typography variant="subtitle1">{member.position}</Typography>
              </Box>
              {member.company && (
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ color: 'text.secondary', mb: 2 }}>
                  <Business sx={{ marginRight: 0.5 }} />
                  <Typography variant="subtitle2">{member.company}</Typography>
                </Box>
              )}
              <Box sx={{ mt: 3 }}>
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ color: 'primary.main', mb: 1 }}>
                  <Lightbulb sx={{ marginRight: 1 }} />
                  <Typography variant="h6">Vision</Typography>
                </Box>
                <Typography variant="body1" sx={{ color: 'text.primary', fontStyle: 'italic' }}>
                  {member.vision}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DirectorProfile;
