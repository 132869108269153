import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, Divider } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import BusinessIcon from '@mui/icons-material/Business';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StarIcon from '@mui/icons-material/Star';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DescriptionIcon from '@mui/icons-material/Description';

const AboutUs = () => {
  return (
    <Container maxWidth="lg" sx={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
      {/* Page Header */}
      <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          About Export Bihar
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          A Subsidiary of Traction and Technology Enterprise
        </Typography>
      </Box>

      {/* Company Overview Section */}
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h5" gutterBottom>
          Company Overview
        </Typography>
        <Typography variant="body1">
          Export Bihar, a subsidiary of Traction and Technology Enterprise, is dedicated to bringing Bihar’s finest agricultural products to international markets. 
          Specializing in the export of high-quality grains like rice and maize, we pride ourselves on transparency, quality control, and a strong commitment to our customers.
        </Typography>
      </Box>

      <Divider sx={{ mb: 4 }} />
      
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <BusinessIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6">Registered Business</Typography>
              <Typography variant="body2">
                GST Number: <strong style={{fontSize:"30px"}}>[10CDLPG0788F1ZS]</strong>
              </Typography>
              <Typography variant="body2">
                Registered Address: 1ST FLOOR, P1/10, PATRAKARNAGAR KANKARBAGH, VIDYAPURI COLONY, Patna, Bihar, 800020
              </Typography>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <ImportExportIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6">Import Export Certified</Typography>
              <Typography variant="body2">
                IEC Code: <strong style={{fontSize:"30px"}}>[CDLPG0788F]</strong>
              </Typography>
              <Typography variant="body2">Certified for Quality Exports</Typography>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <VerifiedIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6">Authenticity Guaranteed</Typography>
              <Typography variant="body2">Our products meet international standards for quality and safety.</Typography>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <DescriptionIcon color="secondary" fontSize="large" />
            <Box>
              <Typography variant="h6">Trusted Documentation</Typography>
              <Typography variant="body2">Transparent policies and documentation ensure smooth transactions.</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Why Choose Us Section */}
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h5" gutterBottom>
          Why Choose Export Bihar?
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <BusinessIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography variant="h6" gutterBottom>
                  Established Heritage
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  As part of Traction and Technology Enterprise, Export Bihar brings years of experience and a strong foundation in agricultural expertise.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <VerifiedIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography variant="h6" gutterBottom>
                  Certified Quality
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  We are ISO, APEDA, and FSSAI certified, ensuring our products meet the highest standards for quality and safety.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <FactCheckIcon color="primary" sx={{ fontSize: 50 }} />
                <Typography variant="h6" gutterBottom>
                  Rigorous Quality Control
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Each product undergoes stringent quality checks, ensuring consistency and excellence from farm to export.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Commitment to Customers Section */}
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h5" gutterBottom>
          Our Commitment to Customers
        </Typography>
        <Typography variant="body1">
          At Export Bihar, customer satisfaction is paramount. Our dedicated team works diligently to ensure timely deliveries, 
          exceptional service, and transparent communication, providing international buyers with a trusted source for premium grains from Bihar.
        </Typography>
      </Box>

      {/* Mission Statement Section */}
      <Box sx={{ textAlign: 'center', marginTop: '3rem', padding: '1.5rem', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <StarIcon color="primary" sx={{ fontSize: 40 }} />
        <Typography variant="h6" gutterBottom>
          Mission Statement
        </Typography>
        <Typography variant="body1">
          To serve global markets with the highest quality agricultural products from Bihar, maintaining integrity, sustainability, and customer satisfaction at every step.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUs;
