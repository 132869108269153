import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ProductCard from '../../component/homepage/ProductCard'; // Import the ProductCard component

const sectors = [
  {
    name: 'Maize',
    description: 'bihar leads in maize production',
    price: '99.99',
    image: 'https://kinalglobalcare.com/wp-content/uploads/5-11.jpg', // Real image URL
  },
  {
    name: 'Non Basmati Rice',
    description: 'our bihar largest producer of rice',
    price: '499.99',
    image: 'https://knnindia.co.in/uploads/newsfiles/NON-BASMATI-29-8-2024.jpg', // Real image URL
  },
  {
    name: 'basmati Rice',
    description: 'our bihar largest producer of rice',
    price: '79.99',
    image: 'https://akm-img-a-in.tosshub.com/lingo/ktak/images/story/202407/6698e3f88b9d5-basmati-rice-export-prices-184423426-16x9.jpg', // Real image URL
  },

  {
    name: 'Broken Rice',
    description: 'our bihar largest producer of rice',
    price: '49.99',
    image: 'https://media.assettype.com/agrowon%2F2022-05%2F846627be-4cd3-4d88-92f3-87f5a4ab9929%2FUntitled_design___2022_05_27T172232_503.png?w=480&dpr=2&auto=format%2Ccompress&fit=max&q=85', // Real image URL
  },
  {
    name: 'Wheate',
    description: 'our bihar largest producer of Wheate',
    price: '49.99',
    image: 'https://agriculturepost.com/wp-content/uploads/2022/05/Why-does-India-announce-relaxation-in-wheat-export-Read-hear%E2%80%A6.jpg', // Real image URL
  },
];

const ProductPage = () => {
  return (
    <Box sx={{ padding: 2, backgroundColor: '#F4F4F4' }}>
      <Typography variant="h4" align="center" sx={{ color: '#002F6C', marginBottom: 4 }}>
        Our Import Sectors
      </Typography>
      <Grid container spacing={3}>
        {sectors.map((sector, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ProductCard product={sector} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductPage;
