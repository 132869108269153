import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar, Rating } from '@mui/material';
import { FormatQuote as FormatQuoteIcon } from '@mui/icons-material';

const testimonials = [
  {
    name: "John Doe",
    title: "CEO, Global Imports Inc.",
    feedback: "Export Bihar provided top-notch service with exceptional quality rice and maize. We look forward to a long-term partnership!",
    rating: 5,
    color: "#FFC107",
    avatar: "https://via.placeholder.com/150" // Placeholder image, replace with actual avatar URL if available
  },
  {
    name: "Jane Smith",
    title: "Head of Procurement, AgroMart",
    feedback: "Efficient delivery and unmatched quality! We appreciate their commitment to sustainable and authentic products.",
    rating: 4.5,
    color: "#3E2723",
    avatar: "https://via.placeholder.com/150"
  },
  {
    name: "Carlos Gomez",
    title: "Director, FreshHarvest Ltd.",
    feedback: "A seamless experience from start to finish. Export Bihar is a reliable export partner we can count on.",
    rating: 4,
    color: "#4CAF50",
    avatar: "" // No avatar URL provided
  }
];

const TestimonialComponent = () => {
  return (
    <Box sx={{ py: 5, px: 3, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#333333', mb: 4 }}>
        What Our Clients Say
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card elevation={3} sx={{ borderRadius: 2, p: 3, textAlign: 'center', position: 'relative' }}>
              <Avatar
                src={testimonial.avatar || undefined} // Use image or undefined
                alt={testimonial.name}
                sx={{
                  width: 80,
                  height: 80,
                  margin: '0 auto',
                  mb: 2,
                  border: `4px solid ${testimonial.color}`,
                  backgroundColor: testimonial.color, // Set background color to match border
                  color: '#fff', // Set text color to white
                  fontWeight: 'bold' // Make initials bold
                }}
              >
                {!testimonial.avatar && testimonial.name.charAt(0)} {/* Show first letter if no avatar */}
              </Avatar>
              <FormatQuoteIcon sx={{ color: testimonial.color, fontSize: 40, position: 'absolute', top: -15, left: -15, opacity: 0.1 }} />
              <CardContent>
                <Typography variant="body1" sx={{ color: '#555', mb: 2 }}>
                  "{testimonial.feedback}"
                </Typography>
                <Rating value={testimonial.rating} precision={0.5} readOnly />
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', mt: 1 }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: '#777' }}>
                  {testimonial.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TestimonialComponent;
