import React from 'react';
import { Box, Typography } from '@mui/material';
import imgbg from '../../asset/imgbg.jpeg';

const OverlayTextComponent = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        height: '500px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden', // Prevents overflow
        color: 'white',
        textAlign: 'center',
      }}
    >
      {/* Background image */}
      <Box
        component="img"
        src={imgbg}
        alt="Background"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '500px',
          objectFit: 'cover', // Ensures the image covers the entire area
          zIndex: 0,
        }}
      />
      
      {/* Semi-transparent overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          zIndex: 1,
        }}
      />

      {/* Text overlay */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          maxWidth: '90%',
          padding: '20px',
        }}
      >
        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
          Why Bihar is Key for Rice Exports
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Bihar is one of India’s top rice-exporting regions, known for its high-quality produce and affordable prices. With fertile land and efficient farming practices, rice from Bihar is both nutritious and economically advantageous, making it a smart choice for global importers seeking value and quality. Export Bihar, a subsidiary of Traction and Technology Enterprise, proudly connects buyers to this premium yet cost-effective rice, delivering unmatched quality at competitive rates.
        </Typography>
      </Box>
    </Box>
  );
};

export default OverlayTextComponent;
