import React from 'react';
import { Box, Typography, Stepper, Step, StepLabel, StepContent, Paper, Button } from '@mui/material';
import {
  Agriculture as AgricultureIcon,
  LocalShipping as LocalShippingIcon,
  Verified as VerifiedIcon,
  Inventory as InventoryIcon,
  ShoppingCart as ShoppingCartIcon
} from '@mui/icons-material';

const ExportProcessStepper = () => {
  const steps = [
    {
      label: "Sourcing",
      description: "We source high-quality rice and maize directly from Bihar's finest farms.",
      icon: <AgricultureIcon />,
      color: '#FFC107'
    },
    {
      label: "Quality Assurance",
      description: "Each batch undergoes rigorous quality checks to meet export standards.",
      icon: <VerifiedIcon />,
      color: '#4CAF50'
    },
    {
      label: "Packaging",
      description: "Careful packaging ensures freshness and protection during transit.",
      icon: <InventoryIcon />,
      color: '#FF5722'
    },
    {
      label: "Logistics",
      description: "Efficient logistics for timely delivery to international destinations.",
      icon: <LocalShippingIcon />,
      color: '#3F51B5'
    },
    {
      label: "Customer Support",
      description: "Our team provides full support throughout the export process.",
      icon: <ShoppingCartIcon />,
      color: '#9C27B0'
    }
  ];

  return (
    <Box sx={{ p: 5, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', color: '#3E2723' }}>
        How We Export Rice & Maize
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center', color: '#333333', mb: 4 }}>
        A seamless process ensuring quality and efficiency in every shipment
      </Typography>
      
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index} active={true}>
            <StepLabel
              icon={React.cloneElement(step.icon, { sx: { fontSize: 40, color: step.color } })}
              sx={{
                '& .MuiStepLabel-label': {
                  fontWeight: 'bold',
                  color: step.color,
                  fontSize: '1.2rem',
                },
                '& .MuiStepLabel-iconContainer': {
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.2)',
                  },
                },
              }}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Paper elevation={3} sx={{ p: 3, backgroundColor: step.color + '1A', borderRadius: 2 }}>
                <Typography variant="body2" sx={{ color: '#333333' }}>
                  {step.description}
                </Typography>
              </Paper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button variant="contained" sx={{ backgroundColor: '#FFC107', color: '#3E2723' }}>
          Learn More
        </Button>
      </Box>
    </Box>
  );
};

export default ExportProcessStepper;
