import React from 'react';
import { Grid, Box, Typography, TextField, Button,  Paper , useMediaQuery} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';


  
  

const ContactPage = () => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  return (
    <Box sx={{ padding: { xs: 2, md: 4 } }}>
      {/* Page Title */}
      <Box className="page-title" sx={{ marginBottom: 4 }}>
        <Typography variant="h2">
          Contact <span style={{ fontWeight: 'bold' }}>Us</span>
        </Typography>
        <Typography variant="h6" sx={{ marginTop: 1 }}>
        Khaptahan, Jagdishpur, Bhojpur, Bihar
        </Typography>
      </Box>

      {/* Map Section */}
      <Box
      sx={{
        width: '100%',
        height: '400px',
        mt: 4,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Our Location
      </Typography>
      <iframe
        title="Our Location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d714.0814372818364!2d84.40150550532908!3d25.460364104029453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3992af7adf6ba6d1%3A0xf57ff2de93e76a0e!2sKhaptahan%2C%20Jagdishpur%2C%20Bhojpur%2C%20Bihar%20802158!5e0!3m2!1sen!2sin!4v1698600097897!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>

      {/* Form Section */}
      <Box className="form-section">
        <Typography variant="h4" gutterBottom>
          Request a Call Back
        </Typography>
        <form action="mail.php" method="post" id="contact-form">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="user_name"
                label="Name"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="user_email"
                label="Email"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="user_mobile"
                label="Mobile"
                variant="outlined"
                fullWidth
                required
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="user_address"
                label="Address"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="user_requirement"
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Send a Message
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      {/* Contact Info Box - Using Material-UI Table */}
      <Box className="contact-info-box" sx={{ marginTop: 4, padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Contact Information
      </Typography>
      <Paper sx={{ padding: 2 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 3,
          }}
        >
          {/* Address Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: isMobile ? 'center' : 'left' }}>
            <Typography variant="h6">Address</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <LocationOnIcon sx={{ marginRight: 1 }} />
              <Typography>
                new bypass Road, near Chetan Niwas, Nandlal Chappra, East Laxmi Nagar, Patna, Bihar
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <LocationOnIcon sx={{ marginRight: 1 }} />
              <Typography>P1/10, Vidyapuri Kankarbagh Patna-20</Typography>
            </Box>
          </Box>

          {/* Mobile Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: isMobile ? 'center' : 'left' }}>
            <Typography variant="h6">Mobile</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <PhoneInTalkIcon sx={{ marginRight: 1 }} />
              <a href="tel:6205641861" style={{ color: 'inherit', textDecoration: 'none' }}>+91-6205641861</a>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <PhoneInTalkIcon sx={{ marginRight: 1 }} />
              <a href="tel:7991106305" style={{ color: 'inherit', textDecoration: 'none' }}>+91-7991106305</a>
            </Box>
          </Box>

          {/* Email Section */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: isMobile ? 'center' : 'left' }}>
            <Typography variant="h6">Email</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <EmailIcon sx={{ marginRight: 1 }} />
              <a href="mailto:info@exportbihar.com" style={{ color: 'inherit', textDecoration: 'none' }}>info@exportbihar.com</a>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
    </Box>
  );
};

export default ContactPage;
